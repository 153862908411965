import { Client } from "./Client"
import { Globals } from "../globals"

export const AdminService = {

  getOrganizations: () => {
    return Client().get(`/admin/organizations`).then(response => { return response.data })
  },

  addOrganization: (data) => {
    return Client().post(`/admin/organizations`, data).then(response => { return response.data })
  },

  getOrganization: (code) => {
    return Client().get(`/admin/organizations/${code}`).then(response => { return response.data })
  },

  addDepartment: (codes, data) => {
    return Client().post(`/admin/organizations/${codes.organization}/areas`, data).then(response => { return response.data })
  },

  getArea: (codes) => {
    return Client().get(`/admin/organizations/${codes.organization}/areas/${codes.area}`).then(response => { return response.data })
  },

  getAreaReadStatics: (codes) => {
    return Client().get(`/admin/organizations/${codes.organization}/areas/${codes.area}/read-statistics`).then(response => { return response.data })
  },
  
  searchEmployees: (data) => {
    return Client().post(`/admin/employees/search`, data).then(response => { return response.data })
  },

  addEmployee: (codes, data) => {
    return Client().post(`/admin/organizations/${codes.organization}/areas/${codes.area}/employees`, data).then(response => { return response.data })
  },

  getEmployee: (codes) => {
    return Client().get(`/admin/organizations/${codes.organization}/areas/${codes.area}/employees/${codes.user}`).then(response => { return response.data })
  },

  getEmployeeFiles: (codes) => {
    return Client().get(`/admin/organizations/${codes.organization}/areas/${codes.area}/employees/${codes.user}/files`).then(response => { return response.data })
  },

  searchFiles: (data) => {
    return Client().post(`/admin/files/search`, data).then(response => { return response.data })
  },

  uploadEmployees: (data) => {
    return Client().post(`/admin/employees`, data, { "Content-Type": "multipart/form-data", timeout: Globals.FILE_UPLOAD_TIMEOUT }).then(response => { return response.data })
  },

  uploadFiles: (data) => {
    return Client().post(`/admin/files`, data, { "Content-Type": "multipart/form-data", timeout: Globals.FILE_UPLOAD_TIMEOUT }).then(response => { return response.data })
  },

  uploadEmployeesAndFiles: (data) => {
    return Client().post(`/admin/employees-files`, data, { "Content-Type": "multipart/form-data", timeout: Globals.FILE_UPLOAD_TIMEOUT }).then(response => { return response.data })
  },

  uploadSingleFile: (data, codes) => {
    return Client().post(`/admin/organizations/${codes.organization}/areas/${codes.area}/employees/${codes.user}/files`, data, { "Content-Type": "multipart/form-data", timeout: Globals.FILE_UPLOAD_TIMEOUT }).then(response => { return response.data })
  },

  retrieveFile: (id) => {
    return Client().get(`/admin/files/${id}`, { responseType: 'blob', timeout: Globals.FILE_DOWNLOAD_TIMEOUT }).then((response) => {
      return new Blob([response.data], { type: response.headers['content-type'] })
    })
  },
  
  deleteFile: (id) => {
    return Client().delete(`/admin/files/${id}`).then(response => { return response.data })
  }

}
