import React, { useState } from "react"
import { ServiceLocator } from "../services/ServiceLocator"
import { useContext } from "../context/Context"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

const sessionService = ServiceLocator.SessionService

export default function Recover() {

  const { register, handleSubmit, formState: { errors } } = useForm()
  const [send, setSend] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const { alert } = useContext()
  const navigate = useNavigate()

  const onSubmin = (data) => {
    alert.set({})
    setSend(true)
    sessionService.forgotPassword(data)
      .then(() => { 
        setConfirmed(true)
        alert.set({ message: 'Correo de recuperación enviado.', type: 'success' }) 
      })
      .catch(() => {
        setSend(false)
        alert.set({ message: 'Error al recuperar cuenta.', type: 'warning' })
      })
  }

  const backToLogin = () => {
    alert.set({})
    navigate('/login')
  }

  return (
    <div className="container user-select-none">
      <div className="position-absolute top-50 start-50 translate-middle" style={{ maxWidth: "400px", minWidth: "300px" }}>
        <div className="text-center">
          <h1 className="mb-3"> Recuperar cuenta </h1>
          <p> Si olvidó la contraseña de su cuenta o tiene problemas para iniciar sesión, ingrese su CUIL y le enviaremos un enlace de recuperación al email registrado. </p>
        </div>
        <form className="d-flex flex-column bd-highlight mb-3" onSubmit={handleSubmit(onSubmin)}>
          <label className="form-label mt-2"> Usuario</label>
          <input className="form-control" type="text" disabled={send} name="username" autoComplete="off" maxLength="11" {...register('username', { required: { value: true, message: 'Este campo es requerido' } })} />
          {errors.username && <span className="text-danger">{errors.username.message}</span>}
          <input className="btn btn-primary mt-3" type="submit" disabled={send} value="Enviar correo de recuperación" />
          <button hidden={!confirmed} className="btn btn-primary mt-1" type="button" disabled={!send} onClick={backToLogin}> Volver a inicio de sesión </button>
        </form>
      </div>
    </div>
  )

}
