import { SessionController } from "../controllers/SessionController"
import axios from "axios"

export const Client = () => {
  return axios.create({
    baseURL: 'https://weliqrecibos.com:8443',
    timeout: 4000,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${SessionController.getActiveSession()}`
    }
  })
}
