import React, { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { ServiceLocator } from "../services/ServiceLocator"
import { useState } from "react"
import { Utils } from "../utils"
import Loader from "./Loader"
import { useContext } from "../context/Context"
import { useNavigate } from "react-router-dom"
import { SessionController } from "../controllers/SessionController"
import { Globals } from "../globals"

const adminService = ServiceLocator.AdminService

export default function MasiveLoadModal(props) {

  const { register, setValue, handleSubmit, reset, watch, formState: { errors } } = useForm()
  const [step, setStep] = useState(1)
  const [loadedEmployees, setLoadedEmployees] = useState([])
  const [loadedFiles, setLoadedFiles] = useState([])
  const [isValidLoad, setIsValidLoad] = useState([])
  const [validationData, setValidationData] = useState({})
  const [successLoad, setSuccessLoad] = useState(true)
  const [filesCodesErrors, setFilesCodesErrors] = useState([])
  const [employeesCodesErrors, setEmployeesCodesErrors] = useState([])
  const { user } = useContext()
  const { id, codes, save } = props
  const navigate = useNavigate()
  const employeesReference = useRef()
  const filesReference = useRef()
  employeesReference.current = watch("employees", "")
  filesReference.current = watch("files", "")

  const onSubmit = async (data) => {
    setStep(5)
    executeSave(data)
  }

  const executeSave = (data) => {

    setSuccessLoad(true)

    const formData = new FormData();
    formData.append('organization_code', codes.organization)
    formData.append('area_code', codes.department)
    formData.append('year', data.year)
    formData.append('month', data.month)
    formData.append('document_type', data.document_type)
    formData.append('document_name', data.document_name)
    formData.append('file', data.employees[0])

    for (let i = 0; i < data.files.length; i++) {
      formData.append('files', data.files[i])
    }

    adminService.uploadEmployeesAndFiles(formData)
      .then(() => {
        setStep(6)
        save()
      })
      .catch(e => {
        setStep(6)
        setSuccessLoad(false)
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
  }

  const showStep = (currentStep) => {
    return step !== currentStep ? 'd-none d-print-block' : ''
  }

  const showActiveProgress = (currentStep) => {
    return `position-absolute top-0 translate-middle btn btn-sm rounded-pill ${currentStep <= step ? 'btn-primary' : 'btn-secondary'}`
  }

  const close = () => {
    setStep(1)
    setValue('employees', {})
    setValue('files', {})
    reset()
  }

  const setVerifyStep = (data) => {
    readEmployeesFileSync(data.employees).then(result => {
      setValidationData(data)
      setLoadedEmployees(result)
      setLoadedFiles(data.files)
      setStep(4)
    })
  }

  const validateIdCodes = () => {
    const employeesCodes = loadedEmployees.flatMap(employee => employee[5])
    const filesCodes = []
    let employeesValidation = true
    let filesValidation = true
    let employeesErrors = []
    let filesErrors = []

    for (let i = 0; i < loadedFiles.length; i++) {
      filesCodes.push(loadedFiles[i].name.split('.')[0])
    }

    employeesCodes.forEach(code => validateCode(filesCodes, code, (errorCode) => { employeesErrors = [...employeesErrors, errorCode] }) ? '' : employeesValidation = false)
    filesCodes.forEach(code => validateCode(employeesCodes, code, (errorCode) => { filesErrors = [...filesErrors, errorCode] }) ? '' : filesValidation = false)

    setEmployeesCodesErrors(employeesErrors)
    setFilesCodesErrors(filesErrors)

    return employeesValidation && filesValidation
  }

  const validateCode = (list, code, errorCallback) => {
    if (list.includes(code)) {
      return true
    } else {
      errorCallback(code)
      return false
    }
  }

  async function readEmployeesFileSync(employees) {
    const file = employees[0]
    let result = await new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (e) => resolve(Utils.parseCsvText(reader.result, '|'))
      reader.readAsText(file)
    })

    return result
  }

  const isEmployeesFileValid = () => {
    return employeesReference?.current?.length > 0 && employeesReference.current[0].type.includes('csv')
  }

  const isEmployeesFileNameValid = () => {
    return employeesReference.current[0].name.includes(`${codes.department}-`)
  }

  useEffect(() => {
    setIsValidLoad(validateIdCodes())
    // eslint-disable-next-line
  }, [loadedEmployees, loadedFiles])

  return (
    <div>
      <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content p-3">

            <div className="p-2 border-bottom">
              <div className="position-relative m-4">
                <div className="progress" style={{ 'height': '1px' }}>
                  <div className="progress-bar" role="progressbar" style={{ 'width': `${step * 25 - 25}%` }} aria-valuenow={step * 25 - 25} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div className={`${showActiveProgress(1)}`} style={{ 'width': '2rem', 'height': '2rem', 'left': '0%' }}>1</div>
                <div className={`${showActiveProgress(2)}`} style={{ 'width': '2rem', 'height': '2rem', 'left': '25%' }}>2</div>
                <div className={`${showActiveProgress(3)}`} style={{ 'width': '2rem', 'height': '2rem', 'left': '50%' }}>3</div>
                <div className={`${showActiveProgress(4)}`} style={{ 'width': '2rem', 'height': '2rem', 'left': '75%' }}>4</div>
                <div className={`${showActiveProgress(6)}`} style={{ 'width': '2rem', 'height': '2rem', 'left': '100%' }}>5</div>
              </div>
            </div>

            <div className={`${showStep(1)}`} id="step1" name="step1">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel"> Definir datos de carga </h5>
              </div>
              <div className="modal-body">
                <form className="d-flex flex-column bd-highlight mb-3" onSubmit={handleSubmit(() => setStep(2))}>
                  <input className="d-none d-print-block" type="submit" />
                  <label className="form-label"> Código de colegio </label>
                  <input className="form-control" type="text" name="code" autoComplete="off" disabled value={codes.organization} />
                  <label className="form-label mt-2"> Código de departamento </label>
                  <input className="form-control" type="text" name="name" autoComplete="off" disabled value={codes.department} />
                  <label className="form-label mt-2"> Año </label>
                  <input className="form-control" maxLength="4" type="text" name="company_name" autoComplete="off" {...register('year', { required: { value: true, message: 'Este campo es requerido' } })} />
                  {errors.year && <span className="text-danger">{errors.year.message}</span>}
                  <label className="form-label mt-2"> Mes </label>
                  <input className="form-control" maxLength="2" type="text" name="company_name" autoComplete="off" {...register('month', { required: { value: true, message: 'Este campo es requerido' } })} />
                  {errors.month && <span className="text-danger">{errors.month.message}</span>}
                  <label className="form-label mt-2"> Tipo de liquidación </label>
                  <div className="input-group ">
                    <input className="form-control" maxLength="20" type="text" name="company_name" autoComplete="off" {...register('document_type', { required: { value: true, message: 'Este campo es requerido' } })} />
                    <button className="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"></button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      {Globals.DOCUMENT_TYPES.map(type => <li key={type}><button className="dropdown-item" type="button" onClick={() => setValue('document_type', type)}>{type}</button></li>)}
                    </ul>
                  </div>
                  {errors.document_type && <span className="text-danger">{errors.document_type.message}</span>}
                  <label className="form-label mt-2"> Nombre de archivo </label>
                  <input className="form-control" maxLength="20" type="text" name="company_name" autoComplete="off" defaultValue={'recibo'} {...register('document_name', { required: { value: true, message: 'Este campo es requerido' } })} />
                  {errors.document_name && <span className="text-danger">{errors.document_name.message}</span>}
                </form>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" data-bs-dismiss="modal" onClick={close}> Cerrar </button>
                <button className="btn btn-primary" onClick={handleSubmit(() => setStep(2))}> Siguiente </button>
              </div>
            </div>

            <div className={`${showStep(2)}`} id="step2" name="step2">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel"> Seleccionar archivo para precarga de empleados </h5>
              </div>
              <div className="modal-body">
                <div className="accordion pt-3 pb-3" id="accordionStep2">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOneStep2">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneStep2" aria-expanded="true" aria-controls="collapseOneStep2">
                        Información
                      </button>
                    </h2>
                    <div id="collapseOneStep2" className="accordion-collapse collapse show" aria-labelledby="headingOneStep2" data-bs-parent="#accordionStep2">
                      <div className="accordion-body">
                        <p className="m-0">
                          En esta primera instancia, seleccione el archivo con el listado de los empleados para cargar al sistema. Se crearán los usuarios de los empleados que no se encuentren
                          registrados y se les enviará un email automático con sus credenciales de acceso a la plataforma. Esta carga <strong>NO</strong> actualiza datos de empleados existentes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwoStep2">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoStep2" aria-expanded="false" aria-controls="collapseTwoStep2">
                        Requisitos
                      </button>
                    </h2>
                    <div id="collapseTwoStep2" className="accordion-collapse collapse" aria-labelledby="headingTwoStep2" data-bs-parent="#accordionStep2">
                      <div className="accordion-body">
                        <p className="mb-2">La única extensión soportada para este archivo es <strong>.CSV</strong>. El contenido deberá respetar el siguiente formato &#40;no debe incluirse el encabezado de las columnas&#41;:</p>
                        <p className="mb-2"><strong>cuil | dni | nombre | apellido | email | legajo</strong></p>
                        <p className="mb-2">Ejemplo:</p>
                        <p className="mb-0"><strong>20402442774|40244277|Javier|Devoto|jdevoto@gmail.com|11223</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <span> Esta carga se creará en organización <strong>{codes.organization}</strong>, departamento <strong>{codes.department}</strong>. </span>
                </div>
                <div className="mb-3">
                  {(isEmployeesFileValid() && !isEmployeesFileNameValid()) && <span className="text-danger"> ¡Atención! - El archivo seleccionado podría no pertenecer a la organización o departamento actual &#40;<strong>{codes.organization}</strong> - <strong>{codes.department}</strong>&#41;. Por favor, verifique que sea el correcto antes de continuar. </span>}
                </div>
                <form className="d-flex flex-column bd-highlight mb-3 mt-3">
                  <div className="input-group">
                    <input className="form-control" accept=".csv" type="file" ref={employeesReference} {...register("employees")} />
                  </div>
                  <input className="d-none d-print-block" type="submit" />
                </form>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" data-bs-dismiss="modal" onClick={close}> Cerrar </button>
                <button className="btn btn-primary" onClick={() => setStep(1)}> Anterior </button>
                <button className="btn btn-primary" disabled={!isEmployeesFileValid()} onClick={() => setStep(3)}> Siguiente </button>
              </div>
            </div>

            <div className={`${showStep(3)}`} id="step3" name="step3">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel"> Seleccionar archivos para subir </h5>
              </div>
              <div className="modal-body">
                <div className="accordion pt-3 pb-3" id="accordionStep3">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOneStep3">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneStep3" aria-expanded="true" aria-controls="collapseOneStep3">
                        Información
                      </button>
                    </h2>
                    <div id="collapseOneStep3" className="accordion-collapse collapse show" aria-labelledby="headingOneStep3" data-bs-parent="#accordionStep3">
                      <div className="accordion-body">
                        <p className="mb-2"> Seleccione los archivos que desea subir para los empleados. </p>
                        <p className="mb-0"> Los archivos que cargue en esta instancia se cotejarán con el listado de empleados de la instancia anterior. </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwoStep3">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoStep3" aria-expanded="false" aria-controls="collapseTwoStep3">
                        Requisitos
                      </button>
                    </h2>
                    <div id="collapseTwoStep3" className="accordion-collapse collapse" aria-labelledby="headingTwoStep3" data-bs-parent="#accordionStep3">
                      <div className="accordion-body">
                        <p className="mb-2"> Los archivos pueden tener cualquier formato o contenido. </p>
                        <p className="mb-0">
                          Tenga en cuenta que debe haber un archivo por cada empleado del listado anterior y que el nombre de cada archivo
                          debe ser el número de legajo del empleado al que corresponde. De lo contrario, toda la carga se verá interrumpida.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <form className="d-flex flex-column bd-highlight mb-3 mt-3">
                  <div className="input-group">
                    <input className="form-control" multiple type="file" ref={filesReference} {...register("files")} />
                  </div>
                  <input className="d-none d-print-block" type="submit" />
                </form>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" data-bs-dismiss="modal" onClick={close}> Cerrar </button>
                <button className="btn btn-primary" onClick={() => setStep(2)}> Anterior </button>
                <button className="btn btn-primary" disabled={!filesReference?.current?.length} onClick={handleSubmit(setVerifyStep)}> Siguiente </button>
              </div>
            </div>

            <div className={`${showStep(4)}`} id="step4" name="step4">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel"> Verificación </h5>
              </div>
              <div className="modal-body">
                <p className="mb-3"> Antes de ejecutar la carga, verifique que los datos sean correctos. </p>
                <p className="mb-2 ms-3"> Organización: <strong>{codes.organization} </strong></p>
                <p className="mb-2 ms-3"> Departamento: <strong>{codes.department} </strong></p>
                <p className="mb-2 ms-3"> Año: <strong>{validationData.year} </strong></p>
                <p className="mb-2 ms-3"> Mes: <strong>{validationData.month} </strong></p>
                <p className="mb-2 ms-3"> Tipo de liquidación: <strong>{validationData.document_type} </strong></p>
                <p className="mb-2 ms-3"> Nombre de archivo: <strong>{validationData.document_name} </strong></p>
                <p className="mb-2 ms-3"> Empleados cargados en el documento: <strong>{loadedEmployees.length} </strong></p>
                <p className="mb-3 ms-3"> Archivos seleccionados: <strong>{loadedFiles.length} </strong></p>
                <p className="mb-2"> Recuerde que, al ejecutar la carga, se enviará un mail a los empleados involucrados dando aviso del nuevo elemento disponible. </p>
                <p className="mb-0 text-success"> <strong> {isValidLoad && 'Todos los empleados cargados tienen su archivo correspondiente. Puede ejecutar la carga.'} </strong> </p>
                <p className="mb-0 text-danger"> <strong> {!isValidLoad && 'Los empleados no coinciden con los archivos seleccionados. No puede ejecutar la carga.'} </strong> </p>
                <p className="mb-0 text-danger"> <strong> {!isValidLoad && (employeesCodesErrors.length !== 0) && `Los siguientes empleados no tienen archivo:`} </strong> </p>
                {!isValidLoad && (employeesCodesErrors.length !== 0) && employeesCodesErrors.map(code => <p className="mb-0 ms-3 text-danger"> &#8226; {code} </p>)}
                <p className="mb-0 text-danger"> <strong> {!isValidLoad && (filesCodesErrors.length !== 0) && `Los siguientes archivos no tienen empleado:`} </strong> </p>
                {!isValidLoad && (filesCodesErrors.length !== 0) && filesCodesErrors.map(code => <p className="mb-0 ms-3 text-danger"> &#8226; {code} </p>)}
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" data-bs-dismiss="modal" onClick={close}> Cerrar </button>
                <button className="btn btn-primary" onClick={() => setStep(3)}> Anterior </button>
                <button className="btn btn-primary" disabled={!isValidLoad} onClick={handleSubmit(onSubmit)}> Ejecutar </button>
              </div>
            </div>

            <div className={`${showStep(5)}`} id="step4" name="step4">
              <Loader loading={true} />
            </div>

            <div className={`${showStep(6)}`} id="step4" name="step4">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel"> {successLoad ? 'Carga exitosa' : 'Error en la carga'} </h5>
              </div>
              <div className="modal-body">
                <label className={`form-label ${!successLoad && 'text-danger'}`}> {successLoad ? 'La carga se realizó con éxito. Finalice para cerrar el modal.' : 'Ocurrió un error durante la carga de archivos. Por favor inténtelo nuevamente.'} </label>
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary" data-bs-dismiss="modal" onClick={close}> {successLoad ? 'Finalizar' : 'Cerrar'} </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
