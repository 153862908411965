import React, { useEffect, useState } from "react"
import { SessionController } from "../controllers/SessionController"
import { ServiceLocator } from "../services/ServiceLocator"

const Cotnext = React.createContext()
const SessionService = ServiceLocator.SessionService

export function ContextProvider({ children }) {
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({})
  const requiresUserLoad = !user.id && SessionController.getActiveSession()

  const value = {
    user: {
      value: user,
      set: setUser
    },
    loading: {
      value: loading,
      set: setLoading
    },
    alert: {
      value: alert,
      set: setAlert
    }
  }

  const onError = (data) => {
    window.location.href = '/'
    setLoading(false)
    setAlert(data)
    setUser({})
    SessionController.deleteActiveSession()
  }

  const loadUser = () => {
    setLoading(true)
    setAlert({})
    SessionService.getCurrentUserInfo()
      .then(response => {
        setUser(response)
        setLoading(false)
        setAlert({})
      }).catch(() => {onError({ message: 'La sesión ha expirado.', type: 'error' })})
  }

  useEffect(() => {
    requiresUserLoad ? loadUser() : setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Cotnext.Provider value={value}>
      {children}
    </Cotnext.Provider>
  )
}

export function useContext() {
  const context = React.useContext(Cotnext)

  if (context === undefined) {
    throw new Error('Cotnext must be used within a ContextProvider')
  }

  return context
}
