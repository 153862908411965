import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ServiceLocator } from "../../services/ServiceLocator"
import { SessionController } from "../../controllers/SessionController"
import OrganizationCard from "../../components/OrganizationCard"
import OrganizationModal from "../../components/OrganizationModal"
import EmptyListRow from "../../components/EmptyListRow"
import { useContext } from "../../context/Context"
import { Utils } from "../../utils"
import FullLoader from "../../components/FullLoader"

const adminService = ServiceLocator.AdminService

export default function Home() {

  const [loading, setloading] = useState(true)
  const [organizations, setOrganizations] = useState([])
  const { alert, user } = useContext()
  const navigate = useNavigate()
  const modalId = 'organizationModal'

  const load = () => {
    adminService.getOrganizations()
      .then((response) => {
        setOrganizations(response)
      })
      .catch(e => {
        alert.set({ message: 'Error en la carga de datos.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
      .finally(() => setloading(false))
  }

  const confirmation = (data, callback, error) => {
    adminService.addOrganization(data)
      .then((response) => {
        alert.set({ message: 'La organización se creó correctamente.', type: 'success' })
        setOrganizations(prev => [...prev, response])
        callback()
      })
      .catch(e => {
        error()
        alert.set({ message: 'Error al guardar datos.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container pt-2">
      <FullLoader loading={loading} />

      <div hidden={loading} className="card mt-2" style={{ marginBottom: '100px' }}>
        <div className="card-header">
          <div className="row">
            <div className="col-auto me-auto">
              <h3 className="m-0 me-auto">
                <strong>Colegios</strong>
              </h3>
            </div>
            <div className="col-auto">
              <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target={`#${modalId}`}>Nuevo colegio</button>
            </div>
          </div>
        </div>
        <div className="card-body pt-2 pb-2 pl-4 pr-4">
          <div className="row">
            {organizations.length ? organizations.map(organization => <OrganizationCard key={organization.code} organization={organization} />) : <EmptyListRow />}
          </div>
        </div>
      </div>

      <OrganizationModal confirmation={confirmation} id={modalId} />
    </div>
  )

}
