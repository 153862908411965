import { useForm } from "react-hook-form"
import { useState, useRef } from "react"
import { Globals } from "../globals"

export default function SignatureModal(props) {

  const { register, setValue, handleSubmit, reset, watch, formState: { errors } } = useForm()
  const [loading, setLoading] = useState(false)
  const [confirmStep, setConfirmStep] = useState(false)
  const { confirmation, modalId } = props
  const closeRef = useRef()
  const notConforming = useRef()
  notConforming.current = watch("notConforming", false)
  
  const handlerClick = (data) => {
    
    let signatureData
    
    if (data.notConforming) {
      signatureData = {
        conformity: Globals.FILE_NON_CONFORMITY,
        reason: data.reason,
        password: data.password
      }
    } else {
      signatureData = {
        conformity: Globals.FILE_CONFORMITY,
        password: data.password
      }
    }

    if (notConforming.current && !confirmStep) {
      setConfirmStep(true)
    } else {
      handlerConfirmationCLick(signatureData)
    }
  }

  const handlerConfirmationCLick = (signatureData) => {
    setLoading(true)
    confirmation(signatureData, {callbackError: () => setLoading(false), callbackOk: callback})
  }

  const callback = () => {
    close()
    setConfirmStep(false)
    closeRef.current.click()
  }

  const close = () => {
    setConfirmStep(false)
    setLoading(false)
    setValue('notConforming', false)
    setValue('reason', '')
    setValue('password', '')
    reset()
  }

  return (
    <div className="modal fade" id={`${modalId}`} data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="signatureModal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-3">
          <div className="modal-header">
            <h5 className="modal-title" id="signatureModal"> Firmar recibo </h5>
          </div>
          <div className="modal-body">
            <form className="d-flex flex-column bd-highlight mb-3" onSubmit={handleSubmit(handlerClick)}>
              <div className="form-check">
                <input className="form-check-input" disabled={loading || confirmStep} type="checkbox" id="notConforming" name="notConforming" ref={notConforming} {...register('notConforming')} />
                <label className="form-check-label"> Firmar no conforme </label>
              </div>

              <label className={`form-label mt-2 ${loading || confirmStep ? 'opacity-50' : ''} ${!notConforming.current ? 'd-none' : ''}`}> Motivo </label>
              <textarea hidden={!notConforming.current} className="form-control" style={{maxHeight: '150px'}} disabled={loading || !notConforming.current || confirmStep} maxLength="250" name="reason" autoComplete="off" {...register('reason', { required: { value: notConforming.current ? true : false, message: 'Este campo es requerido' } })} />
              {errors.reason && notConforming.current && <span className="text-danger">{errors.reason.message}</span>}

              <label className={`form-label mt-2 ${loading || confirmStep ? 'opacity-50' : ''}`}> Contraseña </label>
              <input className="form-control" disabled={loading || confirmStep} maxLength="20" type="password" name="password" autoComplete="off" {...register('password', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.password && <span className="text-danger">{errors.password.message}</span>}

              <input className="d-none d-print-block" type="submit" />
            </form>
          </div>
          <div hidden={confirmStep} className="modal-footer">
            <button className="btn btn-secondary" disabled={loading || confirmStep} data-bs-dismiss="modal" onClick={close}> Cancelar </button>
            <button className="btn btn-primary" disabled={loading || confirmStep} onClick={handleSubmit(handlerClick)}> 
              <span hidden={!loading} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {`${notConforming.current ? 'Continuar' : ' Firmar'}`}
            </button>
            <button className="d-none d-print-block" ref={closeRef} data-bs-dismiss="modal" onClick={close}></button>
          </div>
          <div hidden={!confirmStep} className="modal-footer">
            <div className="w-100 mb-3 text-center">
              <div className="pt-1 fw-bold">ATENCIÓN: Esta acción es irreversible.</div>
              <div className="pt-3">Deberá ponerse en contacto con su empleador para explicar el motivo de la no conformidad.</div>
              <div className="pt-3">¿Desea continuar?</div>
            </div>
            <button className="btn btn-secondary" disabled={loading} onClick={() => setConfirmStep(false)}> Atras </button>
            <button className="btn btn-primary" disabled={loading} onClick={handleSubmit(handlerClick)}>
              <span hidden={!loading} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {' Firmar no conforme'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )

}
