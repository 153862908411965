import React, { useEffect, useState } from "react"
import { SessionController } from "../../controllers/SessionController"
import { ServiceLocator } from "../../services/ServiceLocator"
import { useNavigate } from "react-router-dom"
import { useContext } from "../../context/Context"
import { useParams } from "react-router-dom"
import { Utils } from "../../utils"
import MasiveLoadModal from "../../components/MasiveLoadModal"
import EmptyListRow from "../../components/EmptyListRow"
import UserModal from "../../components/UserModal"
import UserRow from "../../components/UserRow"
import FullLoader from "../../components/FullLoader"

const adminService = ServiceLocator.AdminService
const EMPTY_VALUE = 'empty'

export default function Department() {

  const [loading, setLoading] = useState(true)
  const [loadingStatics, setLoadingStatics] = useState(false)
  const [employees, setEmployees] = useState([])
  const [department, setDepartment] = useState([])
  const [readStatics, setReadStatics] = useState(EMPTY_VALUE)
  const { organizationCode, departmentCode } = useParams()
  const { alert, user } = useContext()
  const navigate = useNavigate()
  const modalIdUser = 'usersModal'
  const modalIdFiles = 'filesModal'

  const codes = {
    organization: organizationCode,
    department: departmentCode
  }

  const load = () => {
    adminService.getArea({ organization: organizationCode, area: departmentCode })
      .then((response) => {
        setDepartment(response)
        setEmployees(response.employees)
      })
      .catch(e => {
        alert.set({ message: 'Error en la carga de datos.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
      .finally(() => { setLoading(false) })
  }

  const confirmation = (data, callback, error) => {
    adminService.addEmployee({ organization: organizationCode, area: departmentCode }, data)
      .then((response) => {
        alert.set({ message: 'El empleado se creó correctamente.', type: 'success' })
        setEmployees(prev => [...prev, response])
        callback()
      })
      .catch(e => {
        error()
        alert.set({ message: 'Error al guardar datos.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
  }

  const loadReadStatics = () => {
    setLoadingStatics(true)

    adminService.getAreaReadStatics({ organization: organizationCode, area: departmentCode })
      .then((response) => {
        setReadStatics(response)
      })
      .catch(e => {
        alert.set({ message: 'Error al obtener estadísticas.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
      .finally(() => setLoadingStatics(false))
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container pt-2">
      <FullLoader loading={loading} />

      <div hidden={loading} className="card mt-2">
        <div className="card-body">
          <h4 className="card-title"><strong>{department.code}</strong></h4>
          <div className="card-text">
            <div> Nombre: <strong>{department.name}</strong></div>
            <div> Empleados: <strong>{employees.length}</strong></div>
            <div> Visualización general: 
              <strong hidden={readStatics === EMPTY_VALUE}> {Math.trunc(readStatics.read_rate)}% </strong>
              <button hidden={readStatics !== EMPTY_VALUE} className="btn btn-primary btn-sm ms-1" style={{padding: '0.10rem 0.25rem', marginTop: '-5px'}} disabled={loadingStatics} onClick={loadReadStatics}> Mostrar </button>
            </div>
          </div>
        </div>
      </div>

      <div hidden={loading} className="card mt-3">
        <div className="card-body">
          <div className="d-grid gap-2">
            <button className="btn btn-primary btn-lg" type="button" data-bs-toggle="modal" data-bs-target={`#${modalIdFiles}`}>Cargar Archivos</button>
          </div>
        </div>
      </div>

      <div hidden={loading} className="card mt-3" style={{ marginBottom: '100px' }}>
        <div className="card-header">
          <div className="row">
            <div className="col-auto me-auto">
              <h3 className="m-0 me-auto">
                <strong>Empleados</strong>
              </h3>
            </div>
            <div className="col-auto">
              <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target={`#${modalIdUser}`}>Nuevo empleado</button>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="row list-group list-group-flush m-0">
            {employees.length ? employees.map(employee => <UserRow key={employee.id} user={employee} codes={codes} />) : <EmptyListRow />}
          </div>
        </div>
      </div>

      <UserModal confirmation={confirmation} id={modalIdUser} />
      <MasiveLoadModal save={load} id={modalIdFiles} codes={codes} />
    </div>
  )

}
