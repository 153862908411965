export const SessionController = {

  setActiveSession: (session) => {
    localStorage.setItem('activeSession', session)
  },

  getActiveSession: () => {
    return localStorage.getItem('activeSession')
  },

  deleteActiveSession: () => {
    localStorage.removeItem('activeSession')
  },

}