import React from "react"
import { ServiceLocator } from "../services/ServiceLocator"
import { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Utils } from "../utils"
import { useContext } from "../context/Context"
import { Globals } from "../globals"

const adminService = ServiceLocator.AdminService

export default function FileRow(props) {

  const { alert } = useContext()
  const { file, modalReference, selectFile } = props
  const [loading, setLoading] = useState(false)
  const [deleteHover, setDeleteHover] = useState(false)
  const creationDate = new Date(file.creation_date)
  const id = String(file.id).padStart(4, '0')
  const date = String(creationDate.getDate()).padStart(2, '0')
  const month = String(creationDate.getMonth() + 1).padStart(2, '0')
  const year = creationDate.getFullYear()
  const hours = String(creationDate.getHours()).padStart(2, '0')
  const minutes = String(creationDate.getMinutes()).padStart(2, '0')

  const handleClick = (id) => {
    setLoading(true)
    adminService.retrieveFile(id)
      .then((blob) => {
        setLoading(false)
        const fileLocaleUrl = window.URL.createObjectURL(blob)
        window.open(fileLocaleUrl, "_blank").focus()
      }).catch(() => { onError({ message: 'Error al obtener el archivo', type: 'error' }) })
  }

  const handleDelete = (event) => {
    event.stopPropagation()
    selectFile(file)
    modalReference.current.click()
  }

  const onError = (error) => {
    setLoading(false)
    alert.set(error)
  }

  return (
    <button className="list-group-item list-group-item-action position-relative" disabled={loading} aria-current="true" onClick={() => { handleClick(file.id) }}>
      <div className="position-absolute top-50 start-50 translate-middle pt-1">
        <span hidden={!loading} className="spinner-border spinner-border-me text-dark text-opacity-75" role="status" aria-hidden="true"></span>
      </div>
      <div className={`d-flex justify-content-between ${loading && 'text-dark text-opacity-25'}`}>
        <div className="col-auto me-auto">
          <div>
            <strong>
              {`${file.file_name}`}
            </strong>
          </div>
          <div>
            {`${id} - ${date}/${month}/${year} ${hours}:${minutes}`}
          </div>
        </div>
        <div className="col-auto d-flex align-items-center">
          <div className="p-2" hidden={loading || !Utils.isFileSeen(file) || !file.conformity || file.conformity === Globals.FILE_CONFORMITY} >
            <span className="fw-bold" title="firmado no conforme">N/C</span>
          </div>
          <div className="p-2" hidden={loading || !Utils.isFileSeen(file)} >
            <FontAwesomeIcon className="fa-lg text-success" icon="fa-check-double" title="visto" />
          </div>
          <div className="p-2" hidden={loading} >
            <FontAwesomeIcon className={`fa-lg text-danger ${!deleteHover && 'text-opacity-75'}`} icon="fa-trash" title="eliminar" onClick={(e) => { handleDelete(e) }} onMouseEnter={() => { setDeleteHover(true) }} onMouseLeave={() => { setDeleteHover(false) }} />
          </div>
        </div>
      </div>
    </button>
  )

}
