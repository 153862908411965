import {
  Route,
  Routes,
  Navigate
} from "react-router-dom"
import Organization from "../../pages/Admin/Organization"
import Department from "../../pages/Admin/Department"
import Search from "../../pages/Admin/Search"
import Home from "../../pages/Admin/Home"
import User from "../../pages/Admin/User"
import NotFound from "../../pages/NotFound"
import Profile from "../../pages/Admin/Profile"

export default function Routing() {

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/admin/organizations" />} />
      <Route path="/login" element={<Navigate replace to="/admin/organizations" />} />
      <Route path="/admin/organizations" element={<Home />} />
      <Route path="/admin/organizations/:organizationCode" element={<Organization />} />
      <Route path="/admin/organizations/:organizationCode/departments/:departmentCode" element={<Department />} />
      <Route path="/admin/organizations/:organizationCode/departments/:departmentCode/employees/:userId" element={<User />} />
      <Route path="/admin/search" element={<Search />} />
      <Route path="/admin/profile" element={<Profile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )

}
