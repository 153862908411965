import { useContext } from "../context/Context"
import { SessionController } from "../controllers/SessionController"

export default function VerifyCurrentUser() {

  const { user } = useContext()

  return {
    currentUser: SessionController.getActiveSession && user,
    currentUserProfile: user.value ? user.value.user_role : null
  }

}
