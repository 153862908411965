import { useForm } from "react-hook-form"
import { useState, useRef } from "react"

export default function OrganizationModal(props) {

  const { register, handleSubmit, reset, formState: { errors } } = useForm()
  const [loading, setLoading] = useState(false)
  const { confirmation, id } = props
  const closeRef = useRef()

  const save = (data) => {
    setLoading(true)
    confirmation(data, callback, () => setLoading(false))
  }

  const callback = () => {
    close()
    closeRef.current.click()
  }

  const close = () => {
    setLoading(false)
    reset()
  }

  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-3">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel"> Nueva organización </h5>
          </div>
          <div className="modal-body">
            <form className="d-flex flex-column bd-highlight mb-3" onSubmit={handleSubmit(save)}>
              <label className="form-label"> Código </label>
              <input className="form-control" disabled={loading} maxLength="10" type="text" name="code" autoComplete="off" {...register('code', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.code && <span className="text-danger">{errors.code.message}</span>}
              <label className="form-label mt-2"> Nombre </label>
              <input className="form-control" disabled={loading} maxLength="50" type="text" name="name" autoComplete="off" {...register('name', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.name && <span className="text-danger">{errors.name.message}</span>}
              <label className="form-label mt-2"> Razón social </label>
              <input className="form-control" disabled={loading} maxLength="50" type="text" name="company_name" autoComplete="off" {...register('company_name', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.company_name && <span className="text-danger">{errors.company_name.message}</span>}
              <input className="d-none d-print-block" type="submit" />
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" disabled={loading} data-bs-dismiss="modal" onClick={close}> Cerrar </button>
            <button className="btn btn-primary" disabled={loading} onClick={handleSubmit(save)}>
              <span hidden={!loading} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {' Guardar'}
            </button>
            <button className="d-none d-print-block" ref={closeRef} data-bs-dismiss="modal" onClick={close}></button>
          </div>
        </div>
      </div>
    </div>
  )

}
