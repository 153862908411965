import React from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { SessionController } from "../controllers/SessionController"
import { useContext } from "../context/Context"
import { ServiceLocator } from "../services/ServiceLocator"
import { useForm } from "react-hook-form"

const sessionService = ServiceLocator.SessionService

export default function Login() {

  const { register, handleSubmit, formState: { errors } } = useForm()
  const { user, loading, alert } = useContext()
  const navigate = useNavigate()

  const onSubmin = (data) => {
    loading.set(true)
    alert.set({})
    sessionService.login(data)
      .then((response) => {
        SessionController.setActiveSession(response.token)
        setCurrentUser()
      }).catch(() => { onError({ message: 'El usuario o la contraseña son incorrectos', type: 'warning' }) })
  }

  const setCurrentUser = () => {
    sessionService.getCurrentUserInfo()
      .then(response => {
        user.set(response)
        loading.set(false)
        alert.set({})
        navigate('/')
      }).catch(() => { onError({ message: 'Error al obtener los datos del usuario', type: 'error' }) })
  }

  const onError = (error) => {
    loading.set(false)
    alert.set(error)
  }

  return (
    <div className="container user-select-none">
      <div className="position-absolute top-50 start-50 translate-middle" style={{ maxWidth: "400px", minWidth: "300px" }}>
        <div className="text-center">
          <h1 className="mb-0">Inicio de sesión</h1>
        </div>
        <form className="d-flex flex-column bd-highlight mb-3" onSubmit={handleSubmit(onSubmin)}>
          <label className="form-label mt-2"> Usuario</label>
          <input className="form-control" type="text" name="username" autoComplete="off" maxLength="11" {...register('username', { required: { value: true, message: 'Este campo es requerido' } })} />
          {errors.username && <span className="text-danger">{errors.username.message}</span>}
          <label className="form-label mt-2"> Contraseña</label>
          <input className="form-control" type="password" name="password" maxLength="20" {...register('password', { required: { value: true, message: 'Este campo es requerido' } })} />
          {errors.password && <span className="text-danger">{errors.password.message}</span>}
          <input className="btn btn-primary mt-3" type="submit" value="Iniciar Sesión" />
        </form>
        <div className="text-center">
          <NavLink to={'/recover'}>¿Olvidaste tu contraseña?</NavLink>
        </div>
      </div>
    </div>
  )

}
