import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import { ServiceLocator } from "../../services/ServiceLocator"
import { SessionController } from "../../controllers/SessionController"
import { useNavigate } from "react-router-dom"
import { useContext } from "../../context/Context"
import { Utils } from "../../utils"
import FileRow from "../../components/FileRow"
import EmptyListRow from "../../components/EmptyListRow"
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal"
import UniqueLoadModal from "../../components/UniqueLoadModal"
import FullLoader from "../../components/FullLoader"

const adminService = ServiceLocator.AdminService

export default function User() {

  const [loading, setLoading] = useState(true)
  const [files, setFiles] = useState([])
  const [employee, setEmployee] = useState({})
  const [selectedFile, setSelectedFile] = useState()
  const { organizationCode, departmentCode, userId } = useParams()
  const { alert, user } = useContext()
  const navigate = useNavigate()
  const uploadModalId = 'uploadFIleModal'
  const deleteModalId = 'deleteModal'
  const modalReference = useRef()

  const load = () => {
    loadEmployee()
    loadFiles()
  }

  const loadEmployee = () => {
    adminService.getEmployee({ organization: organizationCode, area: departmentCode, user: userId })
      .then((response) => {
        setEmployee(response)
      })
      .catch(e => {
        alert.set({ message: 'Error en la carga de datos.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
  }

  const loadFiles = () => {
    adminService.getEmployeeFiles({ organization: organizationCode, area: departmentCode, user: userId })
      .then((response) => {
        setFiles(response)
      })
      .catch(e => {
        alert.set({ message: 'Error en la carga de datos.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
      .finally(() => { setLoading(false) })
  }

  const deleteFile = (id, callback) => {
    adminService.deleteFile(id)
      .then(() => {
        setFiles(files.filter(file => file.id !== id))
      })
      .catch(e => {
        alert.set({ message: 'No se pudo eliminar.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
      .finally(
        callback()
      )
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container pt-2">
      <FullLoader loading={loading} />

      <div hidden={loading} className="card mt-2">
        <div className="card-body">
          <h4 className="card-title"><strong>{employee.employee_number}</strong></h4>
          <div className="card-text">
            <div>Nombre completo: <strong>{employee.surname}, {employee.name}</strong></div>
            <div>Documento: <strong>{employee.dni}</strong></div>
            <div>CUIL: <strong>{employee.cuil}</strong></div>
            <div>Email: <strong>{employee.email}</strong></div>
            <div>Archivos: <strong>{files.length}</strong></div>
            <div>Visualización de archivos: <strong>{Math.trunc(Utils.calculatePercentage(files.length, Utils.getSeenFiles(files).length))}%</strong></div>
          </div>
        </div>
      </div>

      <div hidden={loading} className="card mt-3" style={{ marginBottom: '100px' }}>
        <div className="card-header">
          <div className="row">
            <div className="col-auto me-auto">
              <h3 className="m-0 me-auto">
                <strong> Recibos </strong>
              </h3>
            </div>
            <div className="col-auto">
              <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target={`#${uploadModalId}`}>Cargar archivo</button>
            </div>
          </div>
        </div>

        <div className="card-body p-0">
          <div className="row list-group list-group-flush m-0">
            {files.length ? files.map(file => <FileRow key={file.id} file={file} modalReference={modalReference} selectFile={setSelectedFile} />) : <EmptyListRow />}
          </div>
        </div>
      </div>

      <button hidden ref={modalReference} className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${deleteModalId}`} />
      <DeleteConfirmationModal confirmation={(callback) => deleteFile(selectedFile.id, callback)} modalId={deleteModalId} />
      <UniqueLoadModal id={uploadModalId} codes={{ organization: organizationCode, area: departmentCode, user: userId }} onSave={loadFiles} />
    </div>
  )

}
