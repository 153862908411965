export const Globals = {
    FILE_UPLOAD_TIMEOUT: 60000,
    FILE_DOWNLOAD_TIMEOUT: 10000,
    FILE_STATUS_READ: 'READ',
    FILE_STATUS_PENDING: 'PENDING_READ',
    FILE_CONFORMITY: 'CONFORMING',
    FILE_NON_CONFORMITY: 'NON_CONFORMING',
    INVALID_CREDENTIALS: '401',
    USER_ROLE_ADMIN: 'ADMIN',
    USER_ROLE_USER: 'USER',
    PAGE_SIZES: [10, 25, 50, 100],
    DOCUMENT_TYPES: ['0-sueldo', '5-incentivo']
}