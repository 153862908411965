import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import { SessionController } from "../../controllers/SessionController"
import { ServiceLocator } from "../../services/ServiceLocator"
import { useContext } from "../../context/Context"
import { Utils } from "../../utils"
import DepartmentCard from "../../components/DepartmentCard"
import DepartmentModal from "../../components/DepartmentModal"
import EmptyListRow from "../../components/EmptyListRow"
import FullLoader from "../../components/FullLoader"

const adminService = ServiceLocator.AdminService

export default function Organization() {

  const [loading, setLoading] = useState(true)
  const [loadingStatics, setLoadingStatics] = useState(false)
  const [departments, setDepartments] = useState([])
  const [organization, setOrganization] = useState({})
  const { organizationCode } = useParams()
  const { alert, user } = useContext()
  const navigate = useNavigate()
  const modalId = 'departmentModal'

  const load = () => {
    adminService.getOrganization(organizationCode)
      .then((response) => {
        setOrganization(response)
        setDepartments(response.areas)
      })
      .catch(e => {
        alert.set({ message: 'Error en la carga de datos.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
      .finally(() => { setLoading(false) })
  }

  const confirmation = (data, callback, error) => {
    adminService.addDepartment({ organization: organizationCode }, data)
      .then((response) => {
        alert.set({ message: 'El departamento se creó correctamente.', type: 'success' })
        setDepartments(prev => [...prev, response])
        callback()
      })
      .catch(e => {
        error()
        alert.set({ message: 'Error al guardar datos.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
  }

  const loadReadStatics = (department) => {
    setLoadingStatics(true)
    let auxDepartments = departments
    auxDepartments[departments.indexOf(currentDepartment => currentDepartment.code === department.code)] = department

    adminService.getAreaReadStatics({ organization: organizationCode, area: department.code })
      .then((response) => {
        department.readStatics = response
        setDepartments(auxDepartments)
      })
      .catch(e => {
        alert.set({ message: 'Error al obtener estadísticas.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
      .finally(() => setLoadingStatics(false))
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container pt-2">
      <FullLoader loading={loading} />

      <div hidden={loading} className="card mt-2">
        <div className="card-body">
          <h4 className="card-title"><strong>{organization.code}</strong></h4>
          <div className="card-text">
            <div>Nombre: <strong>{organization.name}</strong></div>
            <div>Razón social: <strong>{organization.company_name}</strong></div>
            <div>Departamentos: <strong>{departments.length}</strong></div>
          </div>
        </div>
      </div>

      <div hidden={loading} className="card mt-3" style={{ marginBottom: '100px' }}>
        <div className="card-header">
          <div className="row">
            <div className="col-auto me-auto">
              <h3 className="m-0 me-auto">
                <strong>Departamentos</strong>
              </h3>
            </div>
            <div className="col-auto">
              <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target={`#${modalId}`}>Nuevo departamento</button>
            </div>
          </div>
        </div>
        <div className="card-body pt-2 pb-2 pl-4 pr-4">
          <div className="row">
            {departments.length ? departments.map(department => <DepartmentCard key={department.code} department={department} codes={{ organization: organizationCode }} loadReadStatics={loadReadStatics} loadingStatics={loadingStatics} />) : <EmptyListRow />}
          </div>
        </div>
      </div>

      <DepartmentModal confirmation={confirmation} id={modalId} />
    </div>
  )

}
