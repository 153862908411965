import React, { useEffect, useState, useRef } from "react"
import { ServiceLocator } from "../../services/ServiceLocator"
import { SessionController } from "../../controllers/SessionController"
import { useNavigate } from "react-router-dom"
import { useContext } from "../../context/Context"
import { Utils } from "../../utils"
import { useForm } from "react-hook-form"
import FullLoader from "../../components/FullLoader"

const sessionService = ServiceLocator.SessionService

export default function Profile() {

  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState({})
  const [editPassword, setEditPassword] = useState(false)
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm()
  const { user, alert } = useContext()
  const navigate = useNavigate()
  const newPassword = useRef()
  newPassword.current = watch("newPassword", "")

  const load = () => {
    sessionService.getCurrentUserInfo()
      .then((response) => {
        setCurrentUser(response)
      })
      .catch(e => {
        alert.set({ message: 'Error en la carga de datos.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
      .finally(() => { setLoading(false) })
  }

  const save = (data) => {
    sessionService.updatePassword({ old_password: data.oldPassword, new_password: data.newPassword })
      .then(() => {
        alert.set({ message: 'La contraseña se modificó correctamente', type: 'success' })
        clear()
      })
      .catch(e => {
        alert.set({ message: 'Error al actualizar contraseña.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
  }

  const clear = () => {
    setEditPassword(false)
    reset()
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container pt-2">
      <FullLoader loading={loading} />

      <div hidden={loading} className="card mt-2">
        <div className="card-header">
          <h3 className="m-0 me-auto">
            <strong>Datos personales</strong>
          </h3>
        </div>
        <div className="card-body">
          <div className="card-text">
            <div> Usuario: <strong> {currentUser.username || '-'} </strong> </div>
            <div> Nombre: <strong> {currentUser.name || '-'} </strong> </div>
            <div> Apellido: <strong> {currentUser.surname || '-'} </strong> </div>
            <div> Documento: <strong> {currentUser.dni || '-'} </strong> </div>
            <div> Cuil: <strong> {currentUser.cuil || '-'} </strong> </div>
            <div> Email: <strong> {currentUser.email || '-'} </strong> </div>
          </div>
        </div>
      </div>

      <div hidden={loading} className="card mt-3">
        <div className="card-header">
          <h3 className="m-0 me-auto">
            <strong>Cambio de contraseña</strong>
          </h3>
        </div>
        <div className="card-body">
          <div className={`${editPassword ? '' : 'd-flex'} align-items-center justify-content-center`} hidden={editPassword} >
            <button disabled={editPassword} className="btn btn-primary" onClick={() => setEditPassword(true)}>Modificar contraseña</button>
          </div>

          <div hidden={!editPassword}>
            <p className="mb-3">La contraseña debe contar con al menos 8 caracteres, un máximo de 20 y debe contener al menos un número.</p>
          </div>
          <form hidden={!editPassword} className="row g-3" onSubmit={handleSubmit(save)}>

            <div className="col-lg-4">
              <label className="form-label"> Contraseña actual </label>
              <input className="form-control" disabled={!editPassword} type="password" name="oldPassword" autoComplete="off" {...register('oldPassword', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.oldPassword && <span className="text-danger">{errors.oldPassword.message}</span>}
            </div>

            <div className="col-lg-4">
              <label className="form-label"> Nueva contraseña </label>
              <input className="form-control" disabled={!editPassword} type="password" name="newPassword" autoComplete="off" ref={newPassword} {...register('newPassword', {
                required: { value: true, message: 'Este campo es requerido' },
                minLength: { value: 8, message: 'Debe tener al menos 8 caracteres' },
                maxLength: { value: 20, message: 'Debe tener menos de 20 caracteres' },
                validate: (value) => Utils.containNumber(value) || "La contraseña debe tener números"
              })} />
              {errors.newPassword && <span className="text-danger">{errors.newPassword.message}</span>}
            </div>

            <div className="col-lg-4">
              <label className="form-label"> Confirmar nueva contraseña </label>
              <input className="form-control" disabled={!editPassword} type="password" name="reNewPassword" autoComplete="off" {...register('reNewPassword', {
                required: { value: true, message: 'Este campo es requerido' },
                validate: (value) => value === newPassword.current || "La contraseña no coincide"
              })} />
              {errors.reNewPassword && <span className="text-danger">{errors.reNewPassword.message}</span>}
            </div>

            <input className="d-none d-print-block" type="submit" />
          </form>
        </div>
        <div hidden={!editPassword} className="modal-footer">
          <button className="btn btn-secondary" onClick={clear}>Cancelar</button>
          <button className="btn btn-primary" onClick={handleSubmit(save)}>Guardar</button>
        </div>
      </div>
    </div>
  )

}
