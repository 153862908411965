export default function FullLoader(props) {

  const {loading} = props

  return (
    <div hidden={!loading}>
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="spinner-border text-secondary loader-style" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  )
}