import React from "react"
import { ServiceLocator } from "../services/ServiceLocator"
import { useState } from "react"
import { useContext } from "../context/Context"
import { Utils } from "../utils"
import { Globals } from "../globals"

const userService = ServiceLocator.UserService

export default function UserRowFile(props) {

  const { alert } = useContext()
  const { file, readFile, modalReference, selectFile } = props
  const [loading, setLoading] = useState(false)

  const id = String(file.id).padStart(4, '0')

  const creationDate = new Date(file.creation_date)
  const creationDay = String(creationDate.getDate()).padStart(2, '0')
  const creationMonth = String(creationDate.getMonth() + 1).padStart(2, '0')
  const creationYear = String(creationDate.getFullYear())

  const signatureDate = file.conformity_timestamp ? new Date(file.conformity_timestamp) : new Date()
  const signatureDay = String(signatureDate.getDate()).padStart(2, '0')
  const signatureMonth = String(signatureDate.getMonth() + 1).padStart(2, '0')
  const signatureYear = String(signatureDate.getFullYear())
  const signatureHours = String(signatureDate.getHours()).padStart(2, '0')
  const signatureMinutes = String(signatureDate.getMinutes()).padStart(2, '0')

  const handleClick = (id) => {
    setLoading(true)
    userService.getFile(id)
      .then((blob) => {
        downloadFile(blob)
        Utils.isFileSeen(file) ? setLoading(false) : markAsRead(id)
      }).catch(() => { onError({ message: 'Error al obtener el archivo', type: 'error' }) })
  }

  const downloadFile = (blob) => {
    const fileLocaleUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = getFileName()
    link.href = fileLocaleUrl
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const getFileName = () => {
    return id + ' - ' + file.file_name
  }

  const markAsRead = (id) => {
    userService.markFileAsRead(id)
      .then(() => {
        readFile(file)
        setLoading(false)
      }).catch(() => { onError({ message: 'Error al marcar el archivo como leído', type: 'error' }) })
  }

  const handleSignFile = (event) => {

    event.stopPropagation()

    if(!loading && !file.conformity && file.status === Globals.FILE_STATUS_READ) {
      selectFile(file)
      modalReference.current.click()
    }
  }

  const onError = (error) => {
    setLoading(false)
    alert.set(error)
  }

  const allowedToSign = () => {
    return file.status === Globals.FILE_STATUS_READ && !file.conformity && !loading
  }

  return (
    <div className={`list-group-item list-group-item-action position-relative user-select-none ${loading ? 'disabled' : ''}`} style={{ 'cursor': 'pointer' }} aria-current="true" onClick={() => { handleClick(file.id) }}>
      <div className="position-absolute top-50 start-50 translate-middle pt-1">
        <span hidden={!loading} className="spinner-border spinner-border-me text-dark text-opacity-75" role="status" aria-hidden="true"></span>
      </div>
      <div className={`d-flex justify-content-between flex-wrap ${loading ? 'text-dark text-opacity-25' : ''}`}>
        <div>
          <div className="d-flex">
            <div hidden={Utils.isFileSeen(file)} className="pe-2">
              <span className="badge rounded-pill bg-primary">NUEVO</span>
            </div>
            <strong>
              {`${file.file_name}`}
            </strong>
          </div>
          <div className="d-flex flex-wrap">
            <div className="pe-2">{`${id} - ${creationDay}/${creationMonth}/${creationYear}`}</div>
            <div className="pe-2 d-none d-md-block">{`-`}</div>
            <div className="pe-2">{`${file.organization_name}`}</div>
            <div className="pe-2 d-none d-md-block">{`-`}</div>
            <div className="pe-5">{`${file.area_name}`}</div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className={`flex-column align-items-start text-dark text-opacity-50 ${file.conformity ? 'd-flex' : 'd-none'}`} style={{ 'width': '154px' }}>
            <div>
              {`${file.conformity === Globals.FILE_CONFORMITY ? 'Firmado conforme' : 'Firmado no conforme'}`}
            </div>
            <div>
              {`${signatureDay}/${signatureMonth}/${signatureYear} ${signatureHours}:${signatureMinutes}`}
            </div>
          </div>
          <div hidden={file.conformity}>
            <div className={`btn btn-primary btn-md px-2 py-1 my-1 ${!allowedToSign() ? 'disabled' : ''}`} style={ !allowedToSign() ? { 'cursor': 'default', 'pointerEvents': 'auto' } : {}} onClick={(e) => { handleSignFile(e) }}> Firmar </div>
          </div>
        </div>
      </div>
    </div>
  )

}
