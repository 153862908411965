import { NavLink, useNavigate, useLocation } from "react-router-dom"
import { useContext } from "../context/Context"
import { SessionController } from "../controllers/SessionController"
import { Globals } from "../globals"
import { Utils } from "../utils"

export default function Header() {

  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useContext()

  const logout = () => {
    Utils.logout(user, SessionController, navigate)
  }

  const getProfilePath = () => {
    return location.pathname.split('/')[1].concat('/profile')
  }

  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-dark user-select-none">
      <div className="container-fluid">
        <NavLink className="navbar-brand d-flex" to={'/'}>
          <div className="me-2 rounded-circle position-relative" style={{ backgroundColor: 'white', width: 30, height: 30 }}>
            <img src="/file-icon-2.jpg" alt="" width="21" className="position-absolute top-50 start-50 translate-middle" />
          </div>
          WeLiq Recibos
        </NavLink>
        {user.value.id && <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navBar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>}
        {user.value.id && <div className="navbar-collapse collapse" id="navbar">
          <ul className="navbar-nav me-auto">
            <li hidden={user.value.user_role === Globals.USER_ROLE_USER} className="nav-item">
              <NavLink className="nav-link" to={'/admin/search'} aria-current="page" >Buscar</NavLink>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="dropdown" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {user.value.name || 'Mi cuenta'}
              </a>
              <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDropdown">
                <li><NavLink className="dropdown-item" to={getProfilePath()}>Mis datos</NavLink></li>
                <li><label className="dropdown-item" onClick={logout}>Cerrar sesión</label></li>
              </ul>
            </li>
          </ul>
        </div>}
      </div>
    </nav >
  )

}
