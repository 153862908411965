import { NavLink } from "react-router-dom"

export default function UserRow(props) {
  const { codes, user } = props

  return (
    <NavLink to={`/admin/organizations/${codes.organization}/departments/${codes.department}/employees/${user.id}`} className="list-group-item list-group-item-action" aria-current="true">
      <strong>
        {`${user.employee_number} - `}
      </strong>
      {`${user.name} ${user.surname}`}
    </NavLink>
  )
}