import { useForm } from "react-hook-form"
import { useState, useRef } from "react"
import { Utils } from "../utils"

export default function UserModal(props) {

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm()
  const [loading, setLoading] = useState(false)
  const { confirmation, id } = props
  const closeRef = useRef()
  const dni = useRef()
  dni.current = watch("dni", "")

  const save = (data) => {
    setLoading(true)
    confirmation(data, callback, () => setLoading(false))
  }

  const callback = () => {
    close()
    closeRef.current.click()
  }

  const close = () => {
    setLoading(false)
    reset()
  }

  const validateCuil = (value) => {
    return value.slice(2, -1) === dni.current && Utils.validateCuil(value)
  }

  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-3">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel"> Nuevo empleado </h5>
          </div>
          <div className="modal-body">
            <form className="d-flex flex-column bd-highlight mb-3" onSubmit={handleSubmit(save)}>
              <label className="form-label"> Legajo </label>
              <input className="form-control" disabled={loading} maxLength="10" type="text" name="employee_number" autoComplete="off" {...register('employee_number', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.employee_number && <span className="text-danger">{errors.employee_number.message}</span>}
              <label className="form-label mt-2"> Nombre </label>
              <input className="form-control" disabled={loading} maxLength="40" type="text" name="name" autoComplete="off" {...register('name', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.name && <span className="text-danger">{errors.name.message}</span>}
              <label className="form-label mt-2"> Apellido </label>
              <input className="form-control" disabled={loading} maxLength="40" type="text" name="surname" autoComplete="off" {...register('surname', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.surname && <span className="text-danger">{errors.surname.message}</span>}
              <label className="form-label mt-2"> Documento </label>
              <input className="form-control" disabled={loading} maxLength="8" type="text" name="dni" autoComplete="off" ref={dni} {...register('dni', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.dni && <span className="text-danger">{errors.dni.message}</span>}
              <label className="form-label mt-2"> CUIL </label>
              <input className="form-control" disabled={loading} maxLength="11" type="text" name="cuil" autoComplete="off" {...register('cuil', {
                required: { value: true, message: 'Este campo es requerido' },
                validate: (value) => validateCuil(value) || "El cuil no es válido"
              })} />
              {errors.cuil && <span className="text-danger">{errors.cuil.message}</span>}
              <label className="form-label mt-2"> Email </label>
              <input className="form-control" disabled={loading} maxLength="50" type="text" name="email" autoComplete="off" {...register('email', {
                required: { value: true, message: 'Este campo es requerido' },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Dirección de email inválida"
                }
              })} />
              {errors.email && <span className="text-danger">{errors.email.message}</span>}
              <input className="d-none d-print-block" type="submit" />
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" disabled={loading} data-bs-dismiss="modal" onClick={close}> Cerrar </button>
            <button className="btn btn-primary" disabled={loading} onClick={handleSubmit(save)}>
              <span hidden={!loading} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {' Guardar'}
            </button>
            <button className="d-none d-print-block" ref={closeRef} data-bs-dismiss="modal" onClick={close}></button>
          </div>
        </div>
      </div>
    </div>
  )

}
