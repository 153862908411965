import VerifyCurrentUser from "../hooks/VerifyCurrentUser"
import Main from "../routing/Main/Routing"
import User from "../routing/User/Routing"
import Admin from "../routing/Admin/Routing"
import Loading from "../pages/Loading"
import { useContext } from "../context/Context"
import { Globals } from "../globals"

export default function GetRoutes() {

    const { loading } = useContext()
    const verification = VerifyCurrentUser()

    if (!loading.value && verification.currentUser && verification.currentUserProfile === Globals.USER_ROLE_ADMIN) {
        return <Admin />
    }

    if (!loading.value && verification.currentUser && verification.currentUserProfile === Globals.USER_ROLE_USER) {
        return <User />
    }

    if (!loading.value) {
        return <Main />
    }

    return <Loading />

}