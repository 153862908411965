import { BrowserRouter as Router } from "react-router-dom"
import Header from "./components/Header"
import { ContextProvider } from "./context/Context"
import GetRoutes from "./components/GetRoutes"
import SnackBar from "./components/SnackBar"
import Breadcrumb from "./components/Breadcrumb"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faTrashCan, faTrash, faCheckDouble } from '@fortawesome/free-solid-svg-icons'
import './App.css'

library.add(faCheckSquare, faCoffee, faTrashCan, faTrash, faCheckDouble)

export default function App() {

  return (
    <ContextProvider>
      <Router>
        <Header />
        <Breadcrumb />
        <GetRoutes />
        <SnackBar />
      </Router>
    </ContextProvider>
  )
}
