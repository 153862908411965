import { useState, useRef } from "react"

export default function DeleteConfirmationModal(props) {

  const [loading, setLoading] = useState(false)
  const { confirmation, modalId } = props
  const closeRef = useRef()

  const handlerClick = () => {
    setLoading(true)
    confirmation(close)
  }

  const close = () => {
    setLoading(false)
    closeRef.current.click()
  }

  return (
    <div className="modal fade" id={`${modalId}`} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-3">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"> Confirmar acción </h5>
          </div>
          <div className="modal-body">
            ¿Seguro que desea eliminar este elemento?
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" disabled={loading} data-bs-dismiss="modal"> Cancelar </button>
            <button className="btn btn-primary" disabled={loading} onClick={handlerClick}>
              <span hidden={!loading} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {' Aceptar'}
            </button>
            <button className="d-none d-print-block" ref={closeRef} data-bs-dismiss="modal" onClick={close}></button>
          </div>
        </div>
      </div>
    </div>
  )

}
