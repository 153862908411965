import { NavLink, useLocation } from "react-router-dom"
import { useContext } from "../context/Context"

const basePaths = ["", "admin", "organizations", "departments", "employees"]

const getLink = (locations, pathName, finishId) => {
  let link = '/'

  for (let i = 0; i !== finishId; i++) {
    link = link.concat(`${locations[i]}/`)
  }

  return link.concat(pathName)
}

export default function Breadcrumb() {

  const { user } = useContext()
  const location = useLocation()
  const locations = location.pathname.split('/').filter((path) => path !== '')
  const filteredLocations = locations.map((pathName, i) => {
    if (basePaths.includes(pathName)) {
      return 'basePath'
    }

    let url = getLink(locations, pathName, i)

    return {
      url,
      name: pathName
    }
  }).filter(path => path !== 'basePath')

  return (
    <>
      {user.value.id && user.value.user_role === 'ADMIN' &&
        <div className="container">
          <div className="card mt-3">
            <div className="card-body">
              <nav style={{ "--bs-breadcrumb-divider": "'>'" }} aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item"><NavLink to={'/'} className="text-decoration-none"><strong>Home</strong></NavLink></li>
                  {filteredLocations.map((path, i) => (
                    <li key={i} className="breadcrumb-item active text-decoration-none" aria-current="page">
                      <NavLink to={path.url} className="text-decoration-none">
                        <strong>{path.name}</strong>
                      </NavLink>
                    </li>
                  ))}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      }
    </>
  )

}
