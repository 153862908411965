import {
  Route,
  Routes,
  Navigate
} from "react-router-dom"
import Login from "../../pages/Login"
import NotFound from "../../pages/NotFound"
import Recover from "../../pages/Recover"
import Reset from "../../pages/Reset"

export default function Routing() {

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/recover" element={<Recover />} />
      <Route path="/reset" element={<Reset />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )

}
