import React, { useState, useRef } from "react"
import { ServiceLocator } from "../../services/ServiceLocator"
import { SessionController } from "../../controllers/SessionController"
import { useNavigate } from "react-router-dom"
import { useContext } from "../../context/Context"
import { Utils } from "../../utils"
import { Globals } from "../../globals"
import { useForm } from "react-hook-form"
import UserSearchRow from "../../components/UserSearchRow"
import EmptyListRow from "../../components/EmptyListRow"
import Loader from "../../components/Loader"

const adminService = ServiceLocator.AdminService

const sortingOptionsDefault = [
  { name: 'Legajo', value: 'EMPLOYEE_NUMBER', exclusive: 0 },
  { name: 'Legajo descendiente', value: 'EMPLOYEE_NUMBER_DESC', exclusive: 0 },
  { name: 'Nombre', value: 'EMPLOYEE_NAME', exclusive: 1 },
  { name: 'Nombre descendiente', value: 'EMPLOYEE_NAME_DESC', exclusive: 1 }
]

export default function Search() {

  const { register, handleSubmit, watch } = useForm()
  const [employees, setEmployees] = useState([])
  const [sorting, setSorting] = useState([sortingOptionsDefault[0]])
  const [pageSize, setPageSize] = useState(Globals.PAGE_SIZES[2])
  const [pages, setPages] = useState([1])
  const [actualPage, setActualPage] = useState(0)
  const [total, setTotal] = useState(-1)
  const [loading, setLoading] = useState(false)
  const { alert, user } = useContext()
  const navigate = useNavigate()
  const search = useRef()
  search.current = watch("search", "")

  const searchEmployees = (data, page) => {

    setLoading(true)

    const searchData = {
      paging: {
        page: page,
        size: pageSize
      },
      filters: {
        matcher: data.search
      },
      sorting: sorting.flatMap(sort => sort.value)
    }

    adminService.searchEmployees(searchData)
      .then((response) => {
        setEmployees(response.results)
        setTotal(response.paging.total)
        setPages(Utils.generatePages(response.paging.total, response.paging.size))
        setActualPage(page)
      }).catch(e => {
        alert.set({ message: 'Error al obtener los resultados', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      }).finally(() => {
        setLoading(false)
      })
  }

  const selectSort = (sort) => {
    if (sorting.includes(sort)) {
      setSorting(sorting.filter(currentSort => currentSort.value !== sort.value))
    } else {
      let auxSorting = sorting.filter(currentSort => currentSort.exclusive !== sort.exclusive)
      auxSorting.push(sort)
      setSorting(auxSorting)
    }
  }

  return (
    <div className="container pt-2">
      <div className="card mt-2">
        <div className="card-header d-flex justify-content-between">
          <h3 className="m-0">
            <strong>Busqueda</strong>
          </h3>
          <div>
            <div className="btn-group me-1 ms-1 align-items-center">
              <button className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" title="Seleccionar tamaño de paginado" disabled={loading}>
                Paginar: {pageSize}
              </button>
              <ul className="dropdown-menu dropdown-menu-end">
                {Globals.PAGE_SIZES.map(size => <li key={size}><button className={`dropdown-item ${pageSize === size && 'active'}`} onClick={() => setPageSize(size)}>{size}</button></li>)}
              </ul>
            </div>
            <div className="btn-group me-1 ms-1">
              <button className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" title="Seleccionar tipo de orden" disabled={loading}>
                Ordenar
              </button>
              <ul className="dropdown-menu dropdown-menu-end">
                {sortingOptionsDefault.map(option => <li key={option.value}><button className={`dropdown-item ${sorting.includes(option) && 'active'}`} onClick={() => selectSort(option)}>{option.name}</button></li>)}
              </ul>
            </div>
          </div>
        </div>
        <div className={`card-body ${sorting.length !== 0 && 'pt-2'}`}>
          <div className="d-flex flex-row-reverse">
            <div hidden={sorting.length === 0}>
              Orden:
              {sorting.map(sorter => <span key={sorter.value} className="badge rounded-pill bg-dark ps-2 mb-2 ms-1">{sorter.name}</span>)}
            </div>
          </div>
          <form className="d-flex" onSubmit={handleSubmit((data) => searchEmployees(data, 0))}>
            <input className="form-control me-2" maxLength="40" type="text" name="search" autoComplete="off" ref={search} placeholder="Buscar" {...register('search')} />
            <button className="btn btn-primary" type="submit" disabled={loading}> Buscar </button>
          </form>
        </div>
      </div>

      <Loader loading={loading} />

      <div hidden={total === -1 || loading} className="card mt-3" style={{ marginBottom: '100px', zIndex: 0 }}>
        <div className="card-header">
          <h3 className="m-0">
            <strong>Resultados </strong>
            <label>
              <h5 className="m-0">{` - ${total}`}</h5>
            </label>
          </h3>
        </div>
        <div className="card-body p-0">
          <div className="row list-group list-group-flush m-0">
            {employees.length ? employees.map(employee => <UserSearchRow key={employee.id} user={employee} codes={{ organization: employee.organization_code, department: employee.area_code }} />) : <EmptyListRow />}
          </div>
        </div>
        <div hidden={!employees.length} className="border-top">
          <nav className="p-3">
            <ul className="pagination justify-content-end m-0">
              <li className={`page-item ${Utils.isFirstElement(pages, actualPage) && 'disabled'}`}>
                <button className="page-link" onClick={handleSubmit((data) => searchEmployees(data, actualPage - 1))}>Anterior</button>
              </li>
              {pages.map(page => (
                <li key={page} className={`page-item ${page === actualPage && 'active'}`}>
                  <button className="page-link" onClick={handleSubmit((data) => searchEmployees(data, page))}>{page + 1}</button>
                </li>
              ))}
              <li className={`page-item ${Utils.isLastElement(pages, actualPage) && 'disabled'}`}>
                <button className="page-link" onClick={handleSubmit((data) => searchEmployees(data, actualPage + 1))}>Siguiente</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )

}
