import React, { useEffect, useState, useRef } from "react"
import { ServiceLocator } from "../../services/ServiceLocator"
import { SessionController } from "../../controllers/SessionController"
import EmptyListRow from "../../components/EmptyListRow"
import UserRowFile from "../../components/UserFileRow"
import { Globals } from "../../globals"
import { Utils } from "../../utils"
import { useNavigate } from "react-router-dom"
import { useContext } from "../../context/Context"
import FullLoader from "../../components/FullLoader"
import SignatureModal from "../../components/SignatureModal"

const userService = ServiceLocator.UserService

export default function Files() {

  const [loading, setLoading] = useState(true)
  const [files, setFiles] = useState([])
  const [selectedFile, setSelectedFile] = useState()
  const { alert, user } = useContext()
  const navigate = useNavigate()
  const signatureModalId = 'deleteModal'
  const signatureModalReference = useRef()

  const load = () => {
    userService.getFiles()
      .then((response) => {
        setFiles(response)
      })
      .catch(e => {
        alert.set({ message: 'Error en la carga de datos.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
      .finally(() => { setLoading(false) })
  }

  const readFile = (file) => {
    const index = files.findIndex((currentFile => currentFile.id === file.id))
    const auxFIles = files
    auxFIles[index].status = Globals.FILE_STATUS_READ
    setFiles(auxFIles)
  }

  const sign = (signatureData, callback) => {
    userService.signFile(selectedFile.id, signatureData)
      .then(() => {
        callback.callbackOk()
        load()
      })
      .catch(e => {
        alert.set({ message: 'No se pudo firmar el recibo.', type: 'error' })
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
        callback.callbackError()
      })
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container pt-2 user-select-none">
      <FullLoader loading={loading} />

      <div hidden={loading} className="card mt-3" style={{ marginBottom: '100px' }}>
        <div className="card-header">
          <div className="row">
            <div className="col-auto me-auto">
              <h3 className="m-0 me-auto">
                <strong> Recibos </strong>
              </h3>
            </div>
          </div>
        </div>

        <div className="card-body p-0">
          <div className="row list-group list-group-flush m-0">
            {files.length ? files.map(file => <UserRowFile key={file.id} file={file} modalReference={signatureModalReference} readFile={readFile} selectFile={setSelectedFile}/>) : <EmptyListRow />}
          </div>
        </div>
      </div>

      <button hidden ref={signatureModalReference} className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${signatureModalId}`} />
      <SignatureModal confirmation={(signatureData, callback) => sign(signatureData, callback)} modalId={signatureModalId} />
    </div>
  )

}
