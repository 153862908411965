import {
  Route,
  Routes,
  Navigate
} from "react-router-dom"
import Files from "../../pages/User/Files"
import NotFound from "../../pages/NotFound"
import Profile from "../../pages/User/Profile"

export default function Routing() {

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/user/files" />} />
      <Route path="/login" element={<Navigate replace to="/user/files" />} />
      <Route path="/user/files" element={<Files />} />
      <Route path="/user/profile" element={<Profile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )

}
