import { NavLink } from "react-router-dom"
import { useState } from "react"

export default function DepartmentCard(props) {

  const [hover, setHover] = useState(false)
  const { codes, department, loadReadStatics, loadingStatics } = props

  const handlerLoadReadStatics = (event) => {
    event.preventDefault()
    loadReadStatics(department)
  }

  return (
    <div className="col-sm-6 p-2">
      <NavLink to={`/admin/organizations/${codes.organization}/departments/${department.code}`} className={`card text-dark bg-light rounded-3 text-decoration-none ${!hover && 'bg-opacity-25'}`} onMouseEnter={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }}>
        <div className="card-header">{department.name}</div>
        <div className="card-body">
          <h5 className="card-title">{department.code}</h5>
          <div> Visualización general:
            <strong hidden={!department.readStatics} className="ms-1">{department.readStatics ? Math.trunc(department.readStatics.read_rate) : '0'}% </strong>
            <button hidden={department.readStatics} className="btn btn-primary btn-sm ms-1" style={{ padding: '0.10rem 0.25rem', marginTop: '-5px' }} disabled={loadingStatics} onClick={(e) => { handlerLoadReadStatics(e) }}> Mostrar </button>
          </div>
        </div>
      </NavLink>
    </div>
  )
}