export default function NotFound() {
  return (
    <div className="container">
      <div className="w-100 position-relative" style={{ height: '500px' }}>
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <h1 style={{ fontSize: '150px' }}>404</h1>
          <h1>Página no encontrada</h1>
        </div>
      </div>
    </div>
  )
}
