import { NavLink } from "react-router-dom"

export default function UserSearchRow(props) {
  const { codes, user } = props

  return (
    <NavLink to={`/admin/organizations/${codes.organization}/departments/${codes.department}/employees/${user.id}`} className="list-group-item list-group-item-action" aria-current="true">
      <strong>{`${user.employee_number} -`}</strong>
      <label className="ms-1 text-body">{`${user.name} ${user.surname} -`}</label>
      <label className="ms-1 text-black-50">{`${user.organization_code} >`}</label>
      <label className="ms-1 text-black-50">{`${user.area_code}`}</label>
    </NavLink>
  )
}