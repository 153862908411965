export default function Loader(props) {

  const {loading} = props

  return (
    <div hidden={!loading} className="w-100 position-relative" style={{ height: '350px' }}>
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="spinner-border text-secondary loader-style" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  )
}