import { Client } from "./Client"
import { Globals } from "../globals"

export const UserService = {

  markFileAsRead: (id) => {
    return Client().post(`/user/files/${id}/mark-as-read`).then(response => { return response.data })
  },

  signFile: (id, data) => {
    return Client().post(`/user/files/${id}/conformity-sign`, data).then(response => { return response.data })
  },

  getFiles: () => {
    return Client().get(`/user/files`).then(response => { return response.data })
  },

  getFile: (id) => {
    return Client().get(`/user/files/${id}`, { responseType: 'blob', timeout: Globals.FILE_DOWNLOAD_TIMEOUT }).then((response) => {
      return new Blob([response.data], { type: response.headers['content-type'] })
    })
  }

}
