import { Client } from "./Client"

export const SessionService = {

  login: (data) => {
    return Client().post(`/login`, data).then(response => { return response.data })
  },

  forgotPassword: (data) => {
    return Client().post(`/forgot-password-token`, data).then(response => { return response.data })
  },

  resetPassword: (data) => {
    return Client().post(`/reset-password`, data).then(response => { return response.data })
  },

  getCurrentUserInfo: () => {
    return Client().get(`/common/user-info`).then(response => { return response.data })
  },
  
  updateInfo: (data) => {
    return Client().put(`/common/user-info`, data).then(response => { return response.data })
  },
  
  updatePassword: (data) => {
    return Client().post(`/common/change-password`, data).then(response => { return response.data })
  },

  refreshToken: () => {
    return Client().get(`/user/refreshtoken`).then(response => { return response.data })
  }

}
