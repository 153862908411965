import React, { useState, useRef } from "react"
import { ServiceLocator } from "../services/ServiceLocator"
import { useContext } from "../context/Context"
import { useForm } from "react-hook-form"
import { useNavigate, useLocation } from "react-router-dom"
import { Utils } from "../utils"

const sessionService = ServiceLocator.SessionService

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Reset() {

  const { register, handleSubmit, watch, formState: { errors } } = useForm()
  const [send, setSend] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const { alert } = useContext()
  const query = useQuery();
  const navigate = useNavigate()
  const newPassword = useRef()
  newPassword.current = watch("newPassword", "")

  const onSubmin = (data) => {

    const requestData = {
      username: data.username,
      password: data.newPassword,
      token: query.get("token")
    }

    alert.set({})
    setSend(true)
    sessionService.resetPassword(requestData)
      .then(() => {
        setConfirmed(true)
        alert.set({ message: 'Se actualizó la contraseña.', type: 'success' })
      })
      .catch(() => {
        setSend(false)
        alert.set({ message: 'Error al actualizar contraseña.', type: 'warning' })
      })
  }

  const backToLogin = () => {
    alert.set({})
    navigate('/login')
  }

  return (
    <div className="container user-select-none">
      <div className="position-absolute top-50 start-50 translate-middle" style={{ maxWidth: "400px", minWidth: "300px" }}>
        <div className="text-center">
          <h1 className="mb-3"> Nueva contraseña </h1>
          <p> Ingrese su usuario y defina una nueva contraseña para su cuenta. Recuerde que por mayor seguridad la misma debe contar con entre 8  y 20 caracteres y con al menos 1 número. </p>
        </div>
        <form className="d-flex flex-column bd-highlight mb-3" onSubmit={handleSubmit(onSubmin)}>
          <label className="form-label mt-2"> Usuario</label>
          <input className="form-control" type="text" disabled={send} name="username" autoComplete="off" maxLength="11" {...register('username', {
            required: { value: true, message: 'Este campo es requerido' }
          })} />
          {errors.username && <span className="text-danger">{errors.username.message}</span>}

          <label className="form-label mt-2"> Nueva contraseña </label>
          <input className="form-control" type="password" disabled={send} name="newPassword" autoComplete="off" maxLength="20" ref={newPassword} {...register('newPassword', {
            required: { value: true, message: 'Este campo es requerido' },
            minLength: { value: 8, message: 'Debe tener al menos 8 caracteres' },
            maxLength: { value: 20, message: 'Debe tener menos de 20 caracteres' },
            validate: (value) => Utils.containNumber(value) || "La contraseña debe tener números"
          })} />
          {errors.newPassword && <span className="text-danger">{errors.newPassword.message}</span>}

          <label className="form-label mt-2"> Confirmar nueva contraseña </label>
          <input className="form-control" type="password" disabled={send} name="reNewPassword" autoComplete="off" maxLength="20" {...register('reNewPassword', {
            required: { value: true, message: 'Este campo es requerido' },
            validate: (value) => value === newPassword.current || "La contraseña no coincide"
          })} />
          {errors.reNewPassword && <span className="text-danger">{errors.reNewPassword.message}</span>}

          <input className="btn btn-primary mt-3" type="submit" disabled={send} value="Resetear contraseña" />
          <button hidden={!confirmed} className="btn btn-primary mt-1" type="button" disabled={!send} onClick={backToLogin}> Volver a inicio de sesión </button>
        </form>
      </div>
    </div>
  )

}
