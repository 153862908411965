import { NavLink } from "react-router-dom"
import { useState } from "react"

export default function OrganizationCard(props) {

  const [hover, setHover] = useState(false)
  const { organization } = props

  return (
    <div className="col-sm-6 p-2">
      <NavLink to={`/admin/organizations/${organization.code}`} className={`card text-dark bg-light rounded-3 text-decoration-none ${!hover && 'bg-opacity-25'}`} onMouseEnter={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }}>
        <div className="card-header">{organization.name}</div>
        <div className="card-body">
          <h5 className="card-title">{organization.code}</h5>
        </div>
      </NavLink>
    </div >
  )
}