import { Globals } from "./globals"

const isFileSeen = (file) => {
  return file.status === Globals.FILE_STATUS_READ
}

const getSeenFiles = (files) => {
  return files.filter(file => isFileSeen(file))
}

const calculatePercentage = (total, amount) => {
  return total ? amount * 100 / total : 0
}

const logout = (user, controller, navigate) => {
  controller.deleteActiveSession()
  user.set({})
  navigate('/')
}

const logoutSessionExpired = (user, controller, navigate, alert) => {
  alert.set({ message: 'La sesión ha expirado.', type: 'error' })
  logout(user, controller, navigate)
}

const checkSession = (errorMessage, user, controller, navigate, alert) => {
  if (errorMessage.includes(Globals.INVALID_CREDENTIALS)) logoutSessionExpired(user, controller, navigate, alert)
}

const parseCsvText = (text, delim) => {
  const rows = text.split('\r\n').filter(row => row !== '')

  return rows.map(row => {
    const rowObject = {}
    row.split(delim).forEach((attribute, i) => rowObject[i] = attribute)
    return rowObject
  })
}

const isFirstElement = (array, element) => {
  return array[0] === element
}

const isLastElement = (array, element) => {
  return array[array.length - 1] === element
}

const generatePages = (total, pageSize) => {
  const pagesNumber = Math.ceil(total / pageSize)
  const pages = []

  for (let i = 0; i < pagesNumber; i++) {
    pages.push(i)
  }

  return pages
}

const containNumber = (value) => {
  return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].some(number => value.includes(number))
}

const validateCuil = (value) => {

  if (value.length !== 11) {
    return false
  }

  const [checkDigit, ...rest] = value.split('').map(Number).reverse()

  const total = rest.reduce(
    (acc, cur, index) => acc + cur * (2 + (index % 6)),
    0,
  )

  const mod11 = 11 - (total % 11);

  if (mod11 === 11) {
    return checkDigit === 0;
  }

  if (mod11 === 10) {
    return false;
  }

  return checkDigit === mod11;
}

export const Utils = {
  isFileSeen,
  getSeenFiles,
  calculatePercentage,
  logout,
  logoutSessionExpired,
  checkSession,
  parseCsvText,
  isFirstElement,
  isLastElement,
  generatePages,
  containNumber,
  validateCuil
}