import React from "react"
import { useForm } from "react-hook-form"
import { ServiceLocator } from "../services/ServiceLocator"
import { useState } from "react"
import { Utils } from "../utils"
import Loader from "./Loader"
import { useContext } from "../context/Context"
import { useNavigate } from "react-router-dom"
import { SessionController } from "../controllers/SessionController"
import { Globals } from "../globals"

const adminService = ServiceLocator.AdminService

export default function UniqueLoadModal(props) {

  const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm()
  const [loading, setLoading] = useState(false)
  const [finished, setFinished] = useState(false)
  const [successLoad, setSuccessLoad] = useState(true)
  const { id, codes, onSave } = props
  const { user } = useContext()
  const navigate = useNavigate()

  const onSubmit = (data) => {

    setLoading(true)

    const formData = new FormData()
    formData.append('year', data.year)
    formData.append('month', data.month)
    formData.append('document_type', data.document_type)
    formData.append('document_name', data.document_name)
    formData.append('file', data.file[0])

    adminService.uploadSingleFile(formData, codes)
      .then(() => {
        setSuccessLoad(true)
        onSave()
      })
      .catch((e) => {
        setSuccessLoad(false)
        Utils.checkSession(e.message, user, SessionController, navigate, alert)
      })
      .finally(() => {
        setFinished(true)
        setLoading(false)
      })
  }

  const close = () => {
    setSuccessLoad(true)
    setFinished(false)
    reset()
  }

  return (
    <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div hidden={finished} className="modal-content p-3">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel"> Cargar archivo </h5>
          </div>
          <div hidden={loading} className="modal-body">
            <form className="d-flex flex-column bd-highlight mb-3" onSubmit={handleSubmit(onSubmit)}>
              <label className="form-label"> Código de colegio </label>
              <input className="form-control" type="text" name="code" autoComplete="off" disabled value={codes.organization} />
              <label className="form-label mt-2"> Código de departamento </label>
              <input className="form-control" type="text" name="name" autoComplete="off" disabled value={codes.area} />
              <label className="form-label mt-2"> Año </label>
              <input className="form-control" maxLength="4" type="text" name="company_name" autoComplete="off" {...register('year', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.year && <span className="text-danger">{errors.year.message}</span>}
              <label className="form-label mt-2"> Mes </label>
              <input className="form-control" maxLength="2" type="text" name="company_name" autoComplete="off" {...register('month', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.month && <span className="text-danger">{errors.month.message}</span>}
              <label className="form-label mt-2"> Tipo de liquidación </label>
              <div className="input-group ">
                <input className="form-control" maxLength="20" type="text" name="company_name" autoComplete="off" {...register('document_type', { required: { value: true, message: 'Este campo es requerido' } })} />
                <button className="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul className="dropdown-menu dropdown-menu-end">
                  {Globals.DOCUMENT_TYPES.map(type => <li key={type}><button className="dropdown-item" type="button" onClick={() => setValue('document_type', type)}>{type}</button></li>)}
                </ul>
              </div>
              {errors.document_type && <span className="text-danger">{errors.document_type.message}</span>}
              <label className="form-label mt-2"> Nombre de archivo </label>
              <input className="form-control" maxLength="20" type="text" name="company_name" autoComplete="off" defaultValue={'recibo'} {...register('document_name', { required: { value: true, message: 'Este campo es requerido' } })} />
              {errors.document_name && <span className="text-danger">{errors.document_name.message}</span>}
              <label className="form-label mt-2"> Archivo </label>
              <div className="input-group">
                <input className="form-control" type="file" {...register("file", { required: { value: true, message: 'El archivo es requerido' } })} />
              </div>
              {errors.file && <span className="text-danger">{errors.file.message}</span>}
              <input className="d-none d-print-block" type="submit" />
            </form>
          </div>
          <div hidden={loading} className="modal-footer">
            <button className="btn btn-secondary" data-bs-dismiss="modal" onClick={close}> Cerrar </button>
            <button className="btn btn-primary" onClick={handleSubmit(onSubmit)}> Subir </button>
          </div>

          <Loader loading={loading} />
        </div>
        <div hidden={loading || !finished} className="modal-content p-3">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel"> {successLoad ? 'Carga exitosa' : 'Error en la carga'} </h5>
          </div>
          <div className="modal-body">
            <label className={`form-label ${!successLoad && 'text-danger'}`}> {successLoad ? 'La carga se realizó con éxito. Finalice para cerrar el modal.' : 'Ocurrió un error durante la carga de archivos. Por favor inténtelo nuevamente.'} </label>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" data-bs-dismiss="modal" onClick={close}> {successLoad ? 'Finalizar' : 'Cerrar'} </button>
          </div>
        </div>
      </div>
    </div>
  )

}
